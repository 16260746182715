<template>
  <div>
    <b-modal
      id="modal-qr"
      title="Staff Registration Form QR Code"
      centered
      size="sm"
      footer-class="justify-content-center"
    >
      <qrcode-vue
        :value="qrValue"
        :size="200"
        level="H"
        class="text-center"
      ></qrcode-vue>

      <template #modal-footer>
        <b-button variant="success" class="btn-icon" @click="saveQr()">
          <feather-icon icon="DownloadIcon" />
          <span class="align-middle ml-50">Download</span>
        </b-button>
        <b-button variant="primary" class="btn-icon" @click="copyQr()">
          <feather-icon icon="CopyIcon" />
          <span class="align-middle ml-50">Copy</span>
        </b-button>
      </template>
    </b-modal>

    <b-card>
      <b-row class="mt-1">
        <b-col
          xl="2"
          lg="3"
          md="4"
          sm="12"
          cols="12"
          class=""
          v-if="rights.add"
        >
          <b-button
            class=""
            @click="Edit(0)"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Staff Applicant</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-1 d-flex align-items-start"
          :xl="
            reportDomain == 'myskoolhpgs' && $store.state.userData.cId == 1
              ? 6
              : 10
          "
          :lg="
            reportDomain == 'myskoolhpgs' && $store.state.userData.cId == 1
              ? 3
              : 9
          "
          :md="
            reportDomain == 'myskoolhpgs' && $store.state.userData.cId == 1
              ? 8
              : 8
          "
          sm="12"
          cols="12"
        >
          <b-form-group class="w-100">
            <b-input-group>
              <b-input-group-prepend is-text @click="loadSearch()">
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model.trim="searchQuery"
                placeholder="Search..."
                @keyup.enter="loadSearch()"
              >
              </b-form-input>
            </b-input-group>
          </b-form-group>
          <b-button
            v-if="
              reportDomain != 'myskoolhpgs' ||
              (reportDomain == 'myskoolhpgs' && $store.state.userData.cId != 1)
            "
            @click="openQr()"
            variant="outline-secondary"
            class="btn-icon p-0 ml-1"
            v-b-tooltip.hover.top
            title="Generate QR Code"
          >
            <img src="@/assets/images/icons/qr-code.png" width="34" />
          </b-button>
        </b-col>

        <b-col
          v-if="reportDomain == 'myskoolhpgs' && $store.state.userData.cId == 1"
          xl="2"
          lg="3"
          md="6"
          sm="12"
          cols="12"
          class="mb-1 mb-md-0"
        >
          <v-select
            v-model="selectedCampus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="campuses"
            :clearable="false"
            :reduce="(val) => val.id"
            label="branch"
            placeholder="Select campus"
            @input="LoadData('dd')"
          />
          <!-- @input="filterData()" -->
        </b-col>

        <b-col
          v-if="reportDomain == 'myskoolhpgs' && $store.state.userData.cId == 1"
          xl="2"
          lg="3"
          md="6"
          sm="12"
          cols="12"
          class="d-flex align-items-start mb-1 mb-md-0"
        >
          <v-select
            style="flex: 1"
            v-model="currentStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            :clearable="false"
            :reduce="(val) => val.value"
            label="text"
            placeholder="Select status"
            @input="LoadData('dd')"
          />
          <!-- @input="filterData()" -->
          <b-button
            @click="openQr()"
            variant="outline-secondary"
            class="btn-icon p-0 ml-1"
            v-b-tooltip.hover.top
            title="Generate QR Code"
          >
            <img src="@/assets/images/icons/qr-code.png" width="34" />
          </b-button>
        </b-col>

        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="LoadData()"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        :busy="dataLoading"
        :items="items"
        :fields="fields"
        show-empty
        responsive
        hover
        small
        :selectable="rights.edit || rights.interviewInfo || rights.approve"
        select-mode="single"
        @row-selected="Edit($event[0].id)"
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(picture)="data">
          <b-img
            v-if="data.item.picture !== null && data.item.picture !== ''"
            rounded
            :src="data.item.picture"
            height="80px"
            width="80px"
            alt=""
          />
          <b-img
            v-else
            src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
            height="80px"
            width="80px"
          />
        </template>

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="data.item.picture"
                variant="light-primary"
              />
            </template>

            <b-link class="font-weight-bold d-block text-nowrap mb-50">
              <b-badge variant="light-primary">
                {{ data.item.name }}
              </b-badge>
            </b-link>
            <b-link class="font-weight-bold d-block text-nowrap">
              <b-badge variant="light-primary">
                F/N: {{ data.item.father_name }}
              </b-badge>
            </b-link>
          </b-media>
        </template>

        <template #cell(qualification)="data">
          <b-badge style="margin-inline: 2px" variant="light-primary">
            {{ data.item.qualification }}
          </b-badge>
        </template>
        <template #cell(experience)="data">
          <b-badge style="margin-inline: 2px" variant="light-primary">
            {{ data.item.experience }}
          </b-badge>
        </template>
        <template #cell(campus)="data">
          <b-badge variant="light-primary">
            {{ getCampus(data.item.campusID) }}
          </b-badge>
        </template>

        <template #cell(applicantStatus)="data">
          <b-badge
            :variant="
              data.value == 'in progress'
                ? 'light-warning'
                : data.value == 'approved'
                ? 'light-success'
                : 'light-danger'
            "
          >
            {{ !data.value ? "pending" : data.value }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="mr-5 text-right">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              v-if="
                rights.edit &&
                [null, '', 'pending', 'in progress'].includes(
                  data.item.applicantStatus
                )
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.bottomleft
              title="Reject"
              class="btn-icon mr-1"
              @click="changeStatus(data.item)"
            >
              <feather-icon icon="XIcon" class="" />
            </b-button>

            <b-button
              v-if="rights.edit || rights.interviewInfo || rights.approve"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.bottomleft
              title="Edit"
              class="btn-icon mr-1"
              @click="Edit(data.item.id)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.bottomright
              title="Delete"
              class="btn-icon"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormGroup,
  BForm,
  BContainer,
  BSpinner,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { TheMask } from "vue-the-mask";

import QrcodeVue from "qrcode.vue";
import { useClipboard } from "@vueuse/core";
const { copy } = useClipboard();

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    TheMask,
    BInputGroup,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BSpinner,
    BForm,
    BContainer,
    BImg,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    vSelect,
    flatPickr,
    QrcodeVue,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
          hideFee: ch.hideFee,
          interviewInfo: ch.interviewInfo,
          approve: ch.approve,
          editCampus: ch.editCampus,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // console.log(this.rights);

      this.LoadData();
      if (
        this.reportDomain == "myskoolhpgs" &&
        this.$store.state.userData.cId == 1
      ) {
        this.LoadCampuses();
        this.fields.splice(1, 0, { label: "campus", key: "campus" });
      }
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      filterloading: false,
      filter: {
        class: 0,
        section: "",
      },
      classes: [],
      contacterror: false,
      mydocloading: false,
      docsloading: "Upload",
      request: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      departments: [],
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 50,
      totalCount: 0,
      currentStatus: "",
      statusOptions: [
        { text: "All", value: "" },
        { text: "Pending", value: "pending" },
        { text: "In Progress", value: "in progress" },
        { text: "Rejected", value: "rejected" },
      ],
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],

      fields: [
        // { label: "picture", key: "picture" },
        { label: "name", key: "name" },
        // { label: "father name", key: "father_name" },
        { label: "contact", key: "contact_number" },
        { label: "qualification", key: "qualification" },
        { label: "experience", key: "experience" },
        { label: "status", key: "applicantStatus" },
        "actions",
      ],
      currentSections: [],
      items: [],
      allItems: [],
      selectedCampus: this.$store.state.userData.cId,
      rangeDate: null,
      myObj: {
        id: 0,
        name: "",
        father_name: "",
        contact: "",
        email: "",
        address: "",
        purpose: "",
        tag: "",
        date: "0001-01-01T00:00:00",
        campusID: this.$store.state.userData.cId,
        attachments: [],
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      avatarURL:
        "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/110.png",
      campuses: [],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      qrValue: "",
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openQr() {
      this.qrValue = `${window.location.origin}/staff-registration?db=${this.$store.state.userData.db}&cId=${this.$store.state.userData.cId}`;
      this.$bvModal.show("modal-qr");
    },
    copyQr() {
      copy(this.qrValue);
      this.$bvToast.toast("Link Copied.", {
        title: "Copied!",
        variant: "success",
        toaster: "b-toaster-top-center",
        solid: true,
      });
    },
    saveQr() {
      const canvas = document.querySelector("#modal-qr canvas");
      let image = canvas.toDataURL();
      let dLink = document.createElement("a");
      dLink.download = "staff-registration.png";
      dLink.href = image;
      dLink.click();
    },

    filterData() {
      if (this.selectedCampus == 0) {
        this.items = this.allItems;
      } else {
        this.items = this.allItems.filter(
          (el) => el.campusID == this.selectedCampus
        );
      }
      // console.log(this.items);
      if (this.currentStatus != "") this.filterByStatus();
    },
    filterByStatus() {
      if ([null, "", "pending"].includes(this.currentStatus)) {
        this.items = this.items.filter((el) =>
          [null, "", "pending"].includes(el.applicantStatus)
        );
      } else {
        this.items = this.items.filter(
          (el) => el.applicantStatus == this.currentStatus
        );
      }
    },
    getCampus(id) {
      let obj = this.campuses.find((el) => el.id == id);
      return obj ? obj.branch : "";
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
      // console.log(this.campuses);
      // this.campuses.unshift({ id: 0, branch: "All Campuses" });
      // this.selectedCampus = 0;
    },
    Edit(id) {
      // console.log("id", id);
      this.$router.push({
        name: "staff-application",
        params: {
          id: id,
        },
      });
    },
    AddOpen() {
      // console.log("Helloo");
      this.$router.push({
        name: "manage-staff",
        params: {
          id: 0,
        },
      });
    },
    async changeStatus(item) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to reject this application?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Reject it!",
      });

      if (result.isConfirmed) {
        // console.log(item);
        item.applicantStatus = "rejected";
        var status = await this.put({
          url:
            this.$store.state.domain +
            "staff/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: item,
          message: "Applicant rejected!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.LoadData();
        }
      }
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "staff/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Applicant removed successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },

    async LoadData(text) {
      this.dataLoading = true;
      // let myitem = await this.get({
      //   url:
      //     this.$store.state.domain +
      //     "staff/LoadApplicants?db=" +
      //     this.$store.state.userData.db +
      //     "&cId=" +
      //     this.$store.state.userData.cId,
      //   token: this.$store.state.userData.token,
      // });
      if (text == "dd") {
        this.currentPage = 1;
      }
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "staff/LoadDataPagination?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.selectedCampus +
          "&dptID=0&status=applicant&page=" +
          this.currentPage +
          "&applicantStatus=" +
          this.currentStatus,
        token: this.$store.state.userData.token,
        dashboard: true,
      });
      // console.log(myitem);
      this.items = myitem.data;
      this.totalCount = myitem.count;
      this.allItems = myitem.data;

      this.dataLoading = false;
      // console.log("items", this.items);
    },
    async loadSearch() {
      if (this.searchQuery.trim() == "") {
        this.currentPage = 1;
        this.LoadData();
      } else {
        this.dataLoading = true;
        let myitem = await this.get({
          url:
            this.$store.state.domain +
            "staff/Search?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&keyword=" +
            this.searchQuery +
            "&status=applicant",
          token: this.$store.state.userData.token,
        });
        this.items = myitem;
        this.totalCount = 0;
        this.currentPage = 1;
        this.dataLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
